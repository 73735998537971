body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* The Modal (background) */
.my-modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
  
/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.modal-content-small{
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 400px;
}
.albumcard{
    width: 18rem;
    border-radius: 1rem;
    margin: auto;
}

.album-img-preview{
    /*width: 18rem;*/
    height: 14rem;
    object-fit: fill;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.btn-outline-dark{
    min-width: 5rem;
}


.gallery-page{
    min-height: 80vh;
}

.heading-center{
    text-align: center;
}

.gallery-photo{
    width: 18rem;
    height: 14rem;
    object-fit: fill;
}

.gallery-photo-selected{
    width: 18rem;
    height: 14rem;
    object-fit: fill;
    border: 2px solid #0275d8 ;
}

.answer-grid{
    margin-top: 2rem;
    margin-left: 6rem;
}

.answer-header{
    margin-top: 1rem;
    margin-left: 6rem;
}

.answer-row{
    margin-top: 3rem;
    margin-right: 6rem;
}

.check-icon{
    font-size: 1.2rem;
    color: #5cb85c;
}
 
.question-grid{
    margin-top: 5rem;
    padding: 2rem;
}

.trash-button{
    font-size: 1.2rem;
    border: None;
    background-color: transparent;
}

.trash-button:hover{
    color: red;
}

.lock-button{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.lock-button-notAuth{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.lock-button:hover{
    color: #0275d8;
}

.unlock-button{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.unlock-button-notAuth{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.unlock-button:hover{
    color: red;
}

.answer-close-button{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.input-control{
    margin-bottom: 1rem;
}

.mandatory-star{
    color: red;
}

.margin-left-1{
    margin: 0.5rem;
}

.margin-right-1{
    margin: 0.5rem;
}

.question-ask-container{
    background-color: white;
    border-radius: 2rem;
}

.question-wrapper{
    background-color: white;
    border-radius: 2rem;
}

body{
    background-color: gainsboro;
}

.btn-delete{
    min-width: 6rem;
    height: 2rem;
}
.list-subtitle{
    color: grey;
}

.member-tax-circle{
    border: 1px solid black;
    width: 11rem;
    height: 11rem;
    border-radius: 50%;
} 

.tax-circle-txt{
    text-align: center;
}

.trout-carp-img{
    width: 7rem;
}

.lic-sell-cate{
    width: 10rem;
}

.lic-sell-price{
    width: 5rem;
}

.prices-container{
    width: 25rem;
}

.sell-container{
    height: 11rem;
}

.sell-date-input{
    width: 15rem;
}

.sell-date-label{
    width: 10rem;
}

.sell-date-error{
    width: 25rem;
    text-align: center;
}

.new-sell-date-container{
    width: 25rem;
}

.sell-date{
    width: 5rem;
}

.licenses-container-wrapper{
    background-color: white;
    border-radius: 2rem;
    padding: 2rem;
    min-width: 30rem;
    margin: auto;
 }

 .license-sell-del-btn{
     margin-left: 2rem;
 }

 .btn-sell-year-change{
     min-width: 8rem;
     height: 2.5rem;
 }
.person-label{
    width: 10rem;
}

.person-note-wrapper
{
    margin-top: 0.4rem;
    border: 1px solid grey;
    min-height: 14.7rem;
    border-radius: 1rem;
    padding: 0.5rem;
    width: 100%;
}

.ticket-wrapper{
    margin-top: 0.4rem;
    border-radius: 1rem;
    padding: 0.5rem;
}

.ticket-detail-href{
    text-decoration: none;
}

.year-select{
    max-width: 12rem;;
}

.region-year-select{
    max-width: 6rem;
    margin-left: 1rem;
}

.header-wrapper{
    width: 10rem;
}

.person-btn{   
    min-width: 2rem;
}

.person-edit-on{
    min-width: 2rem;
}

.person-alert{
    min-width: 14rem;
    color: red;
}

.transparent-background{
    background-color: transparent;
}

.lookup-ul{
    margin:0;
    padding: 0;
}

.lookup-ul > li{
    list-style-type: none;
    min-height: 2rem;
    font-size: large;
    padding-left: 0.5rem;
}

.lookup-ul > li:hover{
    background-color: dimgrey;
    color: white;
}

.lookup-div{
    position: absolute;
    z-index: 1;
}

.new-btn{
    font-size: 1.5rem;
}

.collapse-header-label{
    font-size: 1.2rem;
}

.collapsable-pane{
    border: 1px solid lightgray;
    padding: 0.2rem;
    border-radius: 0.2rem;
}

.collapsable-header{
    padding-left: 0.2rem;
    padding-right: 1rem;
}

.collapsable-body{
    padding: 0.2rem;
}

.collapsable-btn{
    border: none;
}

.new-person-history-pane{
    padding: 0.2rem;
}

.person-btn-group{
    max-width: 20rem;
}

.person-text-btn{
   width: 5rem;
}

.fishing-license-new{
    border: 1px solid lightgray;
    border-radius: 0.2rem;
    padding: 0.2rem;
}

.new-person-history-pane{
    border: 1px solid lightgray;
    border-radius: 0.2rem;
}

.new-person-history-pane{
    padding: 0.2rem;
}

.person-header-btn-group{
    max-height: 2rem;
}

.checkbox-circle{
    border-radius: 50% !important;
}

.grid-view{
    min-width: 992px;
}

.grid-search-label{
    width: 5.5rem;
}

.grid-search-label-sm{
    width: 3rem;
}

.grid-search-column{
    width: 11rem;
    font-size: small;
}

.grid-search-area{
    width: 30rem;
}

.grid-menu-area{
    min-width: 30rem;
    margin-left: 0rem;
}

.grid-menu-btn{
    width: 6rem;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: none;
    text-align: left;
}

.grid-menu-btn-ml{
    margin-left: 0.5rem;
}

.grid-menu-btn:hover{
    background-color: transparent;
    color: cornflowerblue;
    border-color: cornflowerblue;
}

.grid-view-href{
    color: cornflowerblue;
}

.constraint-not-met{
    color: red;
}

.ul-no-bullets{
    list-style-type: none;
}

.medium-btn{
    width: 9rem;
}

.officers-form{
    max-width: 40rem;
}


.region-summary-table{
    max-width: 50rem;
}

.table-sum-row{
    border-color: black;
}
