.gallery-photo{
    width: 18rem;
    height: 14rem;
    object-fit: fill;
}

.gallery-photo-selected{
    width: 18rem;
    height: 14rem;
    object-fit: fill;
    border: 2px solid #0275d8 ;
}
