.albumcard{
    width: 18rem;
    border-radius: 1rem;
    margin: auto;
}

.album-img-preview{
    /*width: 18rem;*/
    height: 14rem;
    object-fit: fill;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.btn-outline-dark{
    min-width: 5rem;
}


.gallery-page{
    min-height: 80vh;
}

.heading-center{
    text-align: center;
}
