.person-label{
    width: 10rem;
}

.person-note-wrapper
{
    margin-top: 0.4rem;
    border: 1px solid grey;
    min-height: 14.7rem;
    border-radius: 1rem;
    padding: 0.5rem;
    width: 100%;
}

.ticket-wrapper{
    margin-top: 0.4rem;
    border-radius: 1rem;
    padding: 0.5rem;
}

.ticket-detail-href{
    text-decoration: none;
}

.year-select{
    max-width: 12rem;;
}

.region-year-select{
    max-width: 6rem;
    margin-left: 1rem;
}

.header-wrapper{
    width: 10rem;
}

.person-btn{   
    min-width: 2rem;
}

.person-edit-on{
    min-width: 2rem;
}

.person-alert{
    min-width: 14rem;
    color: red;
}

.transparent-background{
    background-color: transparent;
}

.lookup-ul{
    margin:0;
    padding: 0;
}

.lookup-ul > li{
    list-style-type: none;
    min-height: 2rem;
    font-size: large;
    padding-left: 0.5rem;
}

.lookup-ul > li:hover{
    background-color: dimgrey;
    color: white;
}

.lookup-div{
    position: absolute;
    z-index: 1;
}

.new-btn{
    font-size: 1.5rem;
}

.collapse-header-label{
    font-size: 1.2rem;
}

.collapsable-pane{
    border: 1px solid lightgray;
    padding: 0.2rem;
    border-radius: 0.2rem;
}

.collapsable-header{
    padding-left: 0.2rem;
    padding-right: 1rem;
}

.collapsable-body{
    padding: 0.2rem;
}

.collapsable-btn{
    border: none;
}

.new-person-history-pane{
    padding: 0.2rem;
}

.person-btn-group{
    max-width: 20rem;
}

.person-text-btn{
   width: 5rem;
}

.fishing-license-new{
    border: 1px solid lightgray;
    border-radius: 0.2rem;
    padding: 0.2rem;
}

.new-person-history-pane{
    border: 1px solid lightgray;
    border-radius: 0.2rem;
}

.new-person-history-pane{
    padding: 0.2rem;
}

.person-header-btn-group{
    max-height: 2rem;
}

.checkbox-circle{
    border-radius: 50% !important;
}

.grid-view{
    min-width: 992px;
}

.grid-search-label{
    width: 5.5rem;
}

.grid-search-label-sm{
    width: 3rem;
}

.grid-search-column{
    width: 11rem;
    font-size: small;
}

.grid-search-area{
    width: 30rem;
}

.grid-menu-area{
    min-width: 30rem;
    margin-left: 0rem;
}

.grid-menu-btn{
    width: 6rem;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: none;
    text-align: left;
}

.grid-menu-btn-ml{
    margin-left: 0.5rem;
}

.grid-menu-btn:hover{
    background-color: transparent;
    color: cornflowerblue;
    border-color: cornflowerblue;
}

.grid-view-href{
    color: cornflowerblue;
}

.constraint-not-met{
    color: red;
}

.ul-no-bullets{
    list-style-type: none;
}

.medium-btn{
    width: 9rem;
}

.officers-form{
    max-width: 40rem;
}


.region-summary-table{
    max-width: 50rem;
}

.table-sum-row{
    border-color: black;
}