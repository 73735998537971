.list-subtitle{
    color: grey;
}

.member-tax-circle{
    border: 1px solid black;
    width: 11rem;
    height: 11rem;
    border-radius: 50%;
} 

.tax-circle-txt{
    text-align: center;
}

.trout-carp-img{
    width: 7rem;
}

.lic-sell-cate{
    width: 10rem;
}

.lic-sell-price{
    width: 5rem;
}

.prices-container{
    width: 25rem;
}

.sell-container{
    height: 11rem;
}

.sell-date-input{
    width: 15rem;
}

.sell-date-label{
    width: 10rem;
}

.sell-date-error{
    width: 25rem;
    text-align: center;
}

.new-sell-date-container{
    width: 25rem;
}

.sell-date{
    width: 5rem;
}

.licenses-container-wrapper{
    background-color: white;
    border-radius: 2rem;
    padding: 2rem;
    min-width: 30rem;
    margin: auto;
 }

 .license-sell-del-btn{
     margin-left: 2rem;
 }

 .btn-sell-year-change{
     min-width: 8rem;
     height: 2.5rem;
 }