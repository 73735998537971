.answer-grid{
    margin-top: 2rem;
    margin-left: 6rem;
}

.answer-header{
    margin-top: 1rem;
    margin-left: 6rem;
}

.answer-row{
    margin-top: 3rem;
    margin-right: 6rem;
}

.check-icon{
    font-size: 1.2rem;
    color: #5cb85c;
}
 
.question-grid{
    margin-top: 5rem;
    padding: 2rem;
}

.trash-button{
    font-size: 1.2rem;
    border: None;
    background-color: transparent;
}

.trash-button:hover{
    color: red;
}

.lock-button{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.lock-button-notAuth{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.lock-button:hover{
    color: #0275d8;
}

.unlock-button{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.unlock-button-notAuth{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.unlock-button:hover{
    color: red;
}

.answer-close-button{
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
}

.input-control{
    margin-bottom: 1rem;
}

.mandatory-star{
    color: red;
}

.margin-left-1{
    margin: 0.5rem;
}

.margin-right-1{
    margin: 0.5rem;
}

.question-ask-container{
    background-color: white;
    border-radius: 2rem;
}

.question-wrapper{
    background-color: white;
    border-radius: 2rem;
}

body{
    background-color: gainsboro;
}
